/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function ($) {
 // Use this variable to set up the common and page specific functions. If you
 // rename this variable, you will also need to rename the namespace below.
 var Sage = {
  // All pages
  'common': {
   init: function () {
    // JavaScript to be fired on all pages




   },
   finalize: function () {
    // JavaScript to be fired on all pages, after page specific JS is fired

    //    $("h1, h2, h3, h4, h5, h6").each(function () {
    //     var wordArray = $(this).text().split(" ");
    //     if (wordArray.length > 1) {
    //      wordArray[wordArray.length - 2] += "&nbsp;" + wordArray[wordArray.length - 1];
    //      wordArray.pop();
    //      $(this).html(wordArray.join(" "));
    //     }
    //    });

    createVariables();

    var resizeTimer;

    $(window).on('resize', function (e) {

     clearTimeout(resizeTimer);
     resizeTimer = setTimeout(function () {

      createVariables();

     }, 250);

    });

    var $toggle = $('.menu-toggle');
    var $body = $('body');
    var $innerNav = $('.inner-nav');
    var $sidebar = $('.blog-cat');

    function createVariables() {
     if ($('.inner-nav').length) {

      var navHeight = $('.inner-nav').outerHeight();
      vein.inject(':root', {
       '--nav-Height': navHeight + 'px'
      });

     }


    }



    $toggle.click(function () {

     $body.toggleClass('menu-open');
     $toggle.toggleClass('menu-open');

     return false;
    });

    if ($innerNav.length) {

     $innerNav.hcSticky({
      stickTo: 'body',
      stickyClass: 'sticky'
     });

    }
    if ($sidebar.length) {

     var navHeight = $('.inner-nav').outerHeight() + 75;

     $sidebar.hcSticky({
      stickTo: '.blog-main',
      stickyClass: 'sticky',
      top: navHeight
     });

    }

    $('a[href^=#]').not('a[href="#"]').click(function () {

     var target = $(this).attr('href');
     var navHeight = $('.inner-nav').outerHeight() - 5;

     $('html, body').animate({
      scrollTop: $(target).offset().top - navHeight
     }, 1000);


     return false;

    });
    $('.video-over .close').click(function () {
     var myPlayer = videojs("trailer");

     myPlayer.pause();
     $('.video-over').removeClass('video-started');
     $('body').removeClass('playing').addClass('played');
     $('#content').animate({
      opacity: 1
     });
     return false;

    });
    $('.video-toggle').click(function () {


     if ($('body').hasClass('played')) {


      var myPlayer = videojs("trailer");
      $('.video-over').addClass('video-started');
      $('body').addClass('playing');
      myPlayer.play();
      $('#content').animate({
       opacity: 0
      });



     } else {

      $('body').addClass('playing');

      var videoSrc = $('#trailer').attr('data-src');

      var myPlayer = videojs("trailer", {

       controls: true,
       techOrder: ["html5"],
       autoplay: true,
       playsinline: true,
       sources: [{
        "type": "video/mp4",
        "src": videoSrc
                        }],
      }, function () {
       var player = this;

       player.on('ended', function () {

        $('.video-over').removeClass('video-started');
        $('body').removeClass('playing').removeClass('played');
        $('#content').animate({
         opacity: 1
        });

       });
      });
      $('html, body').animate({
       scrollTop: $('#returnTop').offset().top
      }, 1000);

      $('.video-over').addClass('video-started');
      myPlayer.play();
      $('#content').animate({
       opacity: 0
      });

     }



     return false;

    });


    $('.cta-toggle').click(function () {

     $(this).slideUp(300);
     $('#form').slideDown(300);
     $('.cta-remove').slideUp(300);

     return false;
    });
    $('.blog-main p').each(function () {
     var $this = $(this);
     if ($this.html().replace(/\s|&nbsp;/g, '').length == 0)
      $this.remove();
    });

   }
  }, // Home page
  'home': {
   init: function () {
    // JavaScript to be fired on the home page


   },
   finalize: function () {
    // JavaScript to be fired on the home page, after the init JS


   }
  }, // About us page, note the change from about-us to about_us.
  'about_us': {
   init: function () {
    // JavaScript to be fired on the about us page
   }
  }
 };
 // The routing fires all common scripts, followed by the page specific scripts.
 // Add additional events for more control over timing e.g. a finalize event
 var UTIL = {
  fire: function (func, funcname, args) {
   var fire;
   var namespace = Sage;
   funcname = (funcname === undefined) ? 'init' : funcname;
   fire = func !== '';
   fire = fire && namespace[func];
   fire = fire && typeof namespace[func][funcname] === 'function';
   if (fire) {
    namespace[func][funcname](args);
   }
  },
  loadEvents: function () {
   // Fire common init JS
   UTIL.fire('common');
   // Fire page-specific init JS, and then finalize JS
   $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
    UTIL.fire(classnm);
    UTIL.fire(classnm, 'finalize');
   });
   // Fire common finalize JS
   UTIL.fire('common', 'finalize');
  }
 };
 // Load Events
 $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
